import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { mdiFilePdf } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { escala_gris } from '../../utils/constants';
import { useGlobalStyles } from '../../utils/styles';
import { useStyles } from '../../utils/transparencia-difusion/styles';
import { estructuraOrganica } from '../../utils/tu-instituto/constants';

const EstructuraOrganica = () => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
  
    return (
      <Layout>
        <SEO title="Estructura Organíca" />
        <div className={globalClasses.container}>
        <Header title="Estructura Organíca" />
          <div className={globalClasses.content}>
            <Typography>Instituto de Seguridad Social del Estado de Tabasco</Typography>
            <List className={classes.list}>
              {estructuraOrganica.map((document, index) => (
                <a key={index} href={document.file} target="_blank" rel="noopener noreferrer">
                  <ListItem button>
                    <ListItemAvatar>
                      <Icon path={mdiFilePdf} size={1.7} color={escala_gris ? '#767576' : '#e54e5d'}  />
                    </ListItemAvatar>
                    <ListItemText primary={document.name} secondary={document.size} />
                  </ListItem>
                </a>
              ))}
            </List>
          </div>
        </div>
        
  
     
      </Layout>
    );
  };
  
  export default EstructuraOrganica;